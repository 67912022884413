import React from 'react';
import { adminData, baseDataPages, dashboardMenu, demoPages, layoutMenu } from '../menu';
import Footer from '../layout/Footer/Footer';

function getBaseDataRoutes() {
	let baseDataRoutes = [];
	for (let x in baseDataPages.baseData.subMenu) {
		// @ts-ignore
		baseDataRoutes.push({
			// @ts-ignore
			path: baseDataPages.baseData.subMenu[x].path,
			element: null,
			exact: true,
		});
	}
	return baseDataRoutes;
}
function getAdminDataRoutes() {
	let baseDataRoutes = [];
	for (let x in adminData) {
		// @ts-ignore
		baseDataRoutes.push({
			// @ts-ignore
			path: adminData[x].path,
			element: null,
			exact: true,
		});
	}
	return baseDataRoutes;
}

const footers = [
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: adminData.booking.path, element: null, exact: true },
	{ path: adminData.booking.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: null, exact: true },
	{ path: '*', element: <Footer /> },
].concat(getBaseDataRoutes())
	.concat(getAdminDataRoutes());

export default footers;
