import React, { FC, useEffect, useState } from 'react';

import { Coupon, CouponColumns, emptyCoupon } from '../../../models/coupons/coupons.model';
import {
	deleteCoupon,
	getAllCoupons,
	postCoupon,
	updateCoupon,
} from '../../../models/coupons/coupons.repository';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { deleteType, getAllTypes, postType } from '../../../models/type/type.repository';
import { emptyType, Type } from '../../../models/type/type.model';
import { Category, CategoryColumns, emptyCategory } from '../../../models/category/category.model';
import { FieldType } from '../../../components/editWindow';
import {
	deleteCategory,
	getAllCategory,
	postCategory,
	updateCategory,
} from '../../../models/category/category.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataCategory: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const [type, setType] = useState<Type[]>([]);
	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		getAllTypes().then((res: Type[]) => {
			setType(res);
		});
	}, []);

	function getColumns() {
		CategoryColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = type.map((cat) => {
					return { value: cat.ID ? cat.ID : 0, text: cat.Name };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return CategoryColumns;
	}

	return (
		<>
			<Datatable<Category>
				columns={getColumns()}
				delete={deleteCategory}
				emptyValue={emptyCategory}
				getAll={getAllCategory}
				post={postCategory}
				update={updateCategory}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataCategory;
