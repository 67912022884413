import React, { lazy } from 'react';
import { adminData, baseDataPages, dashboardMenu, demoPages, layoutMenu } from '../menu';
import OrderTable from '../pages/adminData/OrderTable';
import TenantTable from '../pages/adminData/TenantTable';
import UserTable from '../pages/adminData/UserTable';
import BaseDataArticle from '../pages/basedata/article/article';
import BaseDataCategory from '../pages/basedata/category/category';
import BaseDataCoupon from '../pages/basedata/coupon/coupon';
import BaseDataEvent from '../pages/basedata/event/event';
import BaseDataGameRewards from '../pages/basedata/gameRewards/gameRewards';
import BaseDataLoyaltyRewards from '../pages/basedata/loyaltyRewards/loyaltyrewards';
import BaseDataSubCategory from '../pages/basedata/subCategory/subCategory';
import BaseDataType from '../pages/basedata/type/type';
import Login from '../pages/presentation/auth/Login';
import BaseDataBusStations from "../pages/basedata/busstations/busStations";
import BaseDataSettings from "../pages/basedata/settings/settings";
import BaseDataAdditionGroups from "../pages/basedata/additionGroups/additionGroups";
import BaseDataAdditionProducts from "../pages/basedata/additionProducts/additionProducts";
import BaseDataAdditionGroupsConnections from "../pages/basedata/additionGroupConnections/additionGroupConnections";
import { TenantInfoColumns } from '../models/tenantInfo/tenantInfo.model';
import TenantInfoTable from '../pages/adminData/TenantInfoTable';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},

	/**
	 * Admin Info
	 */

	{
		path: adminData.booking.path,
		element: <OrderTable name={adminData.booking.text} />,
		exact: true,
	},
	{
		path: adminData.user.path,
		element: <UserTable name={adminData.user.text} />,
		exact: true,
	},
	// {
	// 	path: adminData.tenant.path,
	// 	element: <TenantTable name={adminData.tenant.text} />,
	// 	exact: true,
	// },
	// {
	// 	path: adminData.tenantInfo.path,
	// 	element: <TenantInfoTable name={adminData.tenantInfo.text} />,
	// 	exact: true,
	// },

	// BASE DATA
	{
		path: baseDataPages.baseData.subMenu.settings.path,
		element: <BaseDataSettings name={baseDataPages.baseData.subMenu.settings.text} />,
		exact: true,
	},
	// {
	// 	path: baseDataPages.baseData.subMenu.busstation.path,
	// 	element: <BaseDataBusStations name={baseDataPages.baseData.subMenu.busstation.text} />,
	// 	exact: true,
	// },
	{
		path: baseDataPages.baseData.subMenu.coupon.path,
		element: <BaseDataCoupon name={baseDataPages.baseData.subMenu.coupon.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.additionGroups.path,
		element: <BaseDataAdditionGroups name={baseDataPages.baseData.subMenu.additionGroups.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.additionProducts.path,
		element: <BaseDataAdditionProducts name={baseDataPages.baseData.subMenu.additionProducts.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.additionGroupsConnection.path,
		element: <BaseDataAdditionGroupsConnections name={baseDataPages.baseData.subMenu.additionGroupsConnection.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.type.path,
		element: <BaseDataType name={baseDataPages.baseData.subMenu.type.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.subCategory.path,
		element: <BaseDataSubCategory name={baseDataPages.baseData.subMenu.subCategory.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.category.path,
		element: <BaseDataCategory name={baseDataPages.baseData.subMenu.category.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.event.path,
		element: <BaseDataEvent name={baseDataPages.baseData.subMenu.event.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.loyaltyRewards.path,
		element: (
			<BaseDataLoyaltyRewards name={baseDataPages.baseData.subMenu.loyaltyRewards.text} />
		),
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.article.path,
		element: <BaseDataArticle name={baseDataPages.baseData.subMenu.article.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.gameRewards.path,
		element: <BaseDataGameRewards name={baseDataPages.baseData.subMenu.gameRewards.text} />,
		exact: true,
	},
	{
		path: baseDataPages.baseData.subMenu.gameRewards.path,
		element: <BaseDataGameRewards name={baseDataPages.baseData.subMenu.gameRewards.text} />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: layoutMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
		exact: true,
	},
	{
		path: layoutMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
		exact: true,
	},
	{
		path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
