import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { FieldType } from '../../../components/editWindow';
import { Article, ArticleColumns, emptyArticle } from '../../../models/artikel/artikel.model';
import {
	deleteArticle,
	getAllArticle,
	postArticle,
	updateArticle,
} from '../../../models/artikel/artikel.repository';
import { getAllSubCategory } from '../../../models/warengruppe/warengruppe-repository';
import { SubCategory } from '../../../models/warengruppe/warengruppe.model';
import {
	emptyGameRewards,
	GameRewards,
	GameRewardsColumns,
} from '../../../models/winnings/winnings.model';
import {
	deleteWinningsGameRewards as deleteGameRewards,
	getAllGameRewards,
	postGameRewards,
	updateGameRewards,
} from '../../../models/winnings/winnings.repository';
interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataGameRewards: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [article, setArticle] = useState<Article[]>([]);

	useEffect(() => {
		getAllArticle().then((res: Article[]) => {
			setArticle(res);
		});
	}, []);

	function getColumns() {
		GameRewardsColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = article.map((cat) => {
					return { value: cat.ID ? cat.ID : 0, text: cat.Name };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return GameRewardsColumns;
	}

	return (
		<>
			<Datatable<GameRewards>
				columns={getColumns()}
				delete={deleteGameRewards}
				emptyValue={emptyGameRewards}
				getAll={getAllGameRewards}
				post={postGameRewards}
				update={updateGameRewards}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataGameRewards;
