import React, { FC } from 'react';

import { Coupon, CouponColumns, emptyCoupon } from '../../../models/coupons/coupons.model';
import {
	deleteCoupon,
	getAllCoupons,
	postCoupon,
	updateCoupon,
} from '../../../models/coupons/coupons.repository';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { emptyEvent, EventColumns, Events } from '../../../models/events/event.model';
import {
	deleteEvent,
	getAllEvent,
	postEvent,
	updateEvent,
} from '../../../models/events/event.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataEvent: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Events>
				columns={EventColumns}
				delete={deleteEvent}
				emptyValue={emptyEvent}
				getAll={getAllEvent}
				post={postEvent}
				update={updateEvent}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataEvent;
