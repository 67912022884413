import axios, { AxiosResponse } from 'axios';
import { Article } from './artikel.model';

export function getUrl(): string {
	return `/article`;
}

export function postArticle(artikel: Article): Promise<Article> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), artikel).then((res: any) => {
			resolve(res);
		});
	});
}

export function getAllArticle(): Promise<Article[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Article[]>) => resolve(res.data));
	});
}

export function deleteArticle(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function updateArticle(artikel: Article): Promise<Article> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, artikel).then((res: any) => {
			resolve(res);
		});
	});
}

