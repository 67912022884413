import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import {emptySettings, Settings, SettingsColumns} from '../../../models/settings/settings.model';
import {
	deleteSettings,
	getAllSettings,
	postSettings,
	updateSettings,
} from '../../../models/settings/busstation.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataSettings: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Settings>
				columns={SettingsColumns}
				delete={deleteSettings}
				emptyValue={emptySettings}
				getAll={getAllSettings}
				post={postSettings}
				update={updateSettings}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataSettings;
