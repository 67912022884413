import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { emptyType, Type, TypeColumns } from '../../../models/type/type.model';
import {
	deleteType,
	getAllTypes,
	postType,
	updateType,
} from '../../../models/type/type.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataType: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Type>
				columns={TypeColumns}
				delete={deleteType}
				emptyValue={emptyType}
				getAll={getAllTypes}
				post={postType}
				update={updateType}
				name={name}
				isFluid={true}
			/>
		</>
	);
};

export default BaseDataType;
