import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface GameRewards {
	ImageSource?: string;
	Title: string;
	Anzahl: number;
	ArticleID?: number;
	tenant?: number;
	ID?: number;
}
export const GameRewardsColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Title', name: 'Title', fieldType: FieldType.TextField },
	{ dataName: 'Anzahl', name: 'Anzahl', fieldType: FieldType.Number },
	{
		dataName: 'ArticleID',
		name: 'ArticleID',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'ImageSource', name: 'ImageSource', showImage: true },
];

export const emptyGameRewards: GameRewards = {
	Title: '',
	Anzahl: 0,
	ArticleID: 0,
};
