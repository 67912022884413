import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface SubCategory {
	ID?: number;
	Name: string;
	Kategorie: number;
	Timestamp?: string;
	tenant?: number;
	Type?: string;
	KategorieName?: string;
}

export const SubCategoryColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Name', name: 'name', fieldType: FieldType.TextField },
	{ dataName: 'KategorieName', name: 'KategorieName' },
	{
		dataName: 'Kategorie',
		name: 'Kategorie',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'Type', name: 'Type' },
];

export const emptySubCategory: SubCategory = {
	Name: '',
	Kategorie: 0,
};
