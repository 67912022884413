import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';

export interface AdditionProducts {
	id?: number;
	name: string;
	tenant?: number;
	price: number;
	groupID: number,
}

export const AdditionProductsColumns: Columns[] = [
	{ dataName: 'id', name: 'ID' },
	{ dataName: 'name', name: 'name', fieldType: FieldType.TextField },
	{ dataName: 'groupName', name: 'groupName' },
	{
		dataName: 'groupID',
		name: 'group',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'price', name: 'price', fieldType: FieldType.Number },
];

export const emptyAdditionProducts: AdditionProducts = {
	name: '',
	price: 1.6,
	groupID: 1,
};
