import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface User {
	ID?: number;
	tenant?: number;
	Email: string;
	Type: UserType;
	Password: string;
}

export enum UserType {
	waiter = 'Waiter',
	admin = 'Admin',
	pos = 'POS',
}

export const UserColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Email', name: 'email', fieldType: FieldType.TextField },
	{
		dataName: 'Type',
		name: 'type',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [
			{ text: UserType.admin, value: UserType.admin },
			{ text: UserType.waiter, value: UserType.waiter },
			{ text: UserType.waiter, value: UserType.pos },
		],
	},
	{
		dataName: 'Password',
		name: 'password',
		displayed: false,
		fieldType: FieldType.Password
	},
];

export const emptyUser: User = {
	Email: '',
	Type: UserType.waiter,
	Password: '',
};
