import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';

export interface AdditionGroup {
    id?: number;
    name: string;
    tenant?: number;
    limit?: number;
}

export const AdditionGroupColumns: Columns[] = [
    { dataName: 'id', name: 'ID' },
    { dataName: 'name', name: 'name', fieldType: FieldType.TextField },
    { dataName: 'limit', name: 'limit', fieldType: FieldType.Number },
];

export const emptyAdditionGroup: AdditionGroup = {
    name: ''
};
