import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import {
	AdditionGroup,
	AdditionGroupColumns,
	emptyAdditionGroup,
} from '../../../models/additionGroups/additionGroups.model';
import {
	deleteAdditionGroup,
	getAllAdditionGroups,
	postAdditionGroup,
	updateAdditionGroup,
} from '../../../models/additionGroups/additionGroups.repository';
interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataAdditionGroups: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<AdditionGroup>
				columns={AdditionGroupColumns}
				delete={deleteAdditionGroup}
				emptyValue={emptyAdditionGroup}
				getAll={getAllAdditionGroups}
				post={postAdditionGroup}
				update={updateAdditionGroup}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataAdditionGroups;
