import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { User, UserType } from './nutzer.model';

function getUrl(): string {
	return `/waiter`;
}

export function getAllUser(): Promise<User[]> {
	return new Promise((resolve, reject) => {
		axios.get(getUrl() + '/id').then((res: AxiosResponse<User[]>) => {
			resolve(res.data);
		});
	});
}

export function updateUser(object: User): Promise<User> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, object).then((res: any) => resolve(res));
	});
}

export function postUser(object: User): Promise<User> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), object).then((res: any) => resolve(res));
	});
}

export function deleteUser(id: number): Promise<User> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildUser(Email: string, Password: string, Type: UserType, ID?: number): User {
	return {
		Email,
		Password,
		Type,
		tenant: getTenantID(),
	};
}
