import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { FieldType } from '../../../components/editWindow';
import { Category } from '../../../models/category/category.model';
import { getAllCategory } from '../../../models/category/category.repository';
import {
	deleteSubCategory,
	getAllSubCategory,
	postSubCategory,
	updateSubCategory,
} from '../../../models/warengruppe/warengruppe-repository';
import {
	emptySubCategory,
	SubCategory,
	SubCategoryColumns,
} from '../../../models/warengruppe/warengruppe.model';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataSubCategory: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [category, setCategory] = useState<Category[]>([]);
	useEffect(() => {
		getAllCategory().then((res: Category[]) => {
			setCategory(res);
		});
	}, []);

	function getColumns() {
		SubCategoryColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = category.map((cat) => {
					return { value: cat.ID ? cat.ID : 0, text: cat.Name };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return SubCategoryColumns;
	}

	return (
		<>
			<Datatable<SubCategory>
				columns={getColumns()}
				delete={deleteSubCategory}
				emptyValue={emptySubCategory}
				getAll={getAllSubCategory}
				post={postSubCategory}
				update={updateSubCategory}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataSubCategory;
