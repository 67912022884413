import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';

export interface AdditionGroupConnections {
	id?: number;
	productId: number;
	groupId: number;
	tenant?: number;
	limit?: number;
}

export const AdditionGroupConnectionsColumns: Columns[] = [
	{ dataName: 'id', name: 'ID' },
	{ dataName: 'productName', name: 'productName'},
	{ dataName: 'groupName', name: 'groupName' },
	{
		dataName: 'groupId',
		name: 'group',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{
		dataName: 'productId',
		name: 'product',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'limit', name: 'limit', fieldType: FieldType.Number },
];

export const emptyAdditionGroupConnections: AdditionGroupConnections = {
	productId: 0,
	groupId: 0,
	limit: 0,
};
