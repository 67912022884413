import axios, { AxiosResponse } from 'axios';
import { AbstractRepository } from '../repository/abstract-repository';
import { LoyaltyRewards } from './loyaltyPoints.model';

export function buildLoyaltyRewards(Count: number, Reward: number, ID?: number): LoyaltyRewards {
	return {
		Count,
		Reward,
		ID,
	};
}

function getUrl(): string {
	return `/loyaltyRewards`;
}

export function postLoyaltyRewards(printer: LoyaltyRewards): Promise<LoyaltyRewards> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), printer).then((res: any) => {
			resolve(res);
		});
	});
}

export function getAllLoyaltyRewards(): Promise<LoyaltyRewards[]> {
	return new Promise((resolve, reject) => {
		axios
			.get(`${getUrl()}/id`)
			.then((res: AxiosResponse<LoyaltyRewards[]>) => resolve(res.data));
	});
}

export function deleteLoyaltyRewards(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function updateLoyaltyRewards(printer: LoyaltyRewards): Promise<LoyaltyRewards> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, printer).then((res: any) => {
			resolve(res);
		});
	});
}
