import axios, { AxiosResponse } from 'axios';
import { AbstractRepository } from '../repository/abstract-repository';
import { Order } from './order.model';

export function getUrl(): string {
	return `/orders`;
}
export function getAllOrders(): Promise<Order[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Order[]>) => resolve(res.data));
	});
}

// export function deleteOrder (id: number): Promise < any > {
//     const options = {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: {
//             'ID': id
//         }
//     };
//     return axios.delete(getUrl(), options);
// }

function calculateFromBruttoToNetto(bruttoPrice: number, steuer: number) {
	return Number((bruttoPrice / (steuer / 100 + 1)).toFixed(2));
}
