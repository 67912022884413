import { Columns } from '../../components/Table';

export interface Order {
	ID?: number;
	user: string;
	articleID: number;
	count: number;
	Count?: number;
	info: string;
	reference: string;
	timestamp: Date;
	delivered?: boolean;
	price: number;
	tableName: string;
	payed: string;
	Seat: string;
	inactiveCount: string;
	posID: number;
	ecr: number;
	ArticleName?: string;
	tenant?: number;
	Name?: string;
}

export const OrderColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'user', name: 'user' },
	{ dataName: 'ArticleName', name: 'name' },
	{ dataName: 'count', name: 'count' },
	{ dataName: 'timestamp', name: 'timestamp' },
	{ dataName: 'reference', name: 'reference' },
	{ dataName: 'price', name: 'price' },
	{ dataName: 'tableName', name: 'tableName' },
	{ dataName: 'Seat', name: 'seat' },
	{ dataName: 'payed', name: 'payed' },
];
