import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AdditionGroupConnections } from './additionGroupConnections.model';

function getUrl(): string {
	return `/additionGroupsConnections`;
}

export function getAllAdditionGroupsConnection(): Promise<AdditionGroupConnections[]> {
	return new Promise((resolve, reject) => {
		axios
			.get(`${getUrl()}/id`)
			.then((res: AxiosResponse<AdditionGroupConnections[]>) => resolve(res.data));
	});
}

export function updateAdditionGroupConnection(additionGroup: AdditionGroupConnections): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, additionGroup).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function postAdditionGroupConnection(additionGroup: AdditionGroupConnections): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), additionGroup).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function deleteAdditionGroupConnection(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			id: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildAdditionGroupConnection(
	groupId: number,
	productId: number,
	id?: number,
): AdditionGroupConnections {
	return {
		productId,
		groupId,
		tenant: getTenantID(),
		id,
	};
}
