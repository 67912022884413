import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface Category {
	ID?: number;
	Name: string;
	Type: number;
	Timestamp?: string;
	tenant?: number;
	TypeName?: string;
}

export const CategoryColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Name', name: 'name', fieldType: FieldType.TextField },
	{
		dataName: 'Type',
		name: 'Type',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'TypeName', name: 'TypeName' },
];

export const emptyCategory: Category = {
	Name: '',
	Type: 0,
};
