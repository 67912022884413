import { Columns } from '../../components/Table';
import { FieldType } from '../../components/editWindow';
import { useTranslation } from 'react-i18next';

export interface Coupon {
	ID?: number;
	Name: string;
	Type: CouponType;
	Amount: number;
	Active: boolean;
	tenant?: number;
}

export enum CouponType {
	percent = 'Prozent',
	euro = 'Euro',
}

export const CouponColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Name', name: 'name', fieldType: FieldType.TextField },
	{ dataName: 'Amount', name: 'amount', fieldType: FieldType.Number },
	{ dataName: 'Active', name: 'active', fieldType: FieldType.Switch },
	{
		dataName: 'Type',
		name: 'type',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [
			{ value: CouponType.euro, text: CouponType.euro },
			{ value: CouponType.percent, text: CouponType.percent },
		],
	},
];

export const emptyCoupon: Coupon = {
	Active: true,
	Name: '',
	Amount: 0,
	Type: CouponType.euro,
};
