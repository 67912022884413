import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface Type {
	ID?: number;
	Name: string;
	tenant?: number;
	speakBubbleText: string;
	combinable: boolean;
	position?: number
}

export const TypeColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Name', name: 'name', fieldType: FieldType.TextField },
	{ dataName: 'speakBubbleText', name: 'speakBubbleText', fieldType: FieldType.TextField },
	{ dataName: 'combinable', name: 'combinable', fieldType: FieldType.Switch },
	{ dataName: 'position', name: 'Position', fieldType: FieldType.Number },
];

export const emptyType: Type = {
	Name: '',
	combinable: false,
	speakBubbleText: '',
	position: 0
};

export interface TypeStats {
	Count: number;
	Type: number;
	TypeName: string;
}
