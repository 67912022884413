import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Category } from './category.model';

function getUrl(): string {
	return `/categories`;
}

export function getAllCategory(): Promise<Category[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Category[]>) => resolve(res.data));
	});
}

export function updateCategory(obergruppe: Category): Promise<Category> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, obergruppe).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function postCategory(obergruppe: Category): Promise<Category> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), obergruppe).then((res) => {
			resolve(res.data);
		});
	});
}

export function deleteCategory(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildCategory(Name: string, Type: number, ID?: number): Category {
	return {
		Name,
		Type,
		tenant: getTenantID(),
		ID,
	};
}
