import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { AdditionProductsColumns } from '../../../models/additionProducts/additionProducts.model';
import { FieldType } from '../../../components/editWindow';
import { getAllAdditionGroups } from '../../../models/additionGroups/additionGroups.repository';
import { AdditionGroup } from '../../../models/additionGroups/additionGroups.model';
import {
	AdditionGroupConnections,
	AdditionGroupConnectionsColumns,
	emptyAdditionGroupConnections,
} from '../../../models/additionGroupConnections/additionGroupConnections.model';
import {
	deleteAdditionGroupConnection,
	getAllAdditionGroupsConnection,
	postAdditionGroupConnection,
	updateAdditionGroupConnection,
} from '../../../models/additionGroupConnections/additionGroupConnections.repository';
import { getAllArticle } from '../../../models/artikel/artikel.repository';
import { Article } from '../../../models/artikel/artikel.model';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataAdditionGroupsConnections: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [additionG, setAdditionG] = useState<AdditionGroup[]>([]);
	const [articles, setArticles] = useState<Article[]>([]);

	useEffect(() => {
		getAllAdditionGroups().then((res: AdditionGroup[]) => {
			setAdditionG(res);
		});

		getAllArticle().then((res) => {
			setArticles(res);
		});
	}, []);

	function getColumns() {
		AdditionGroupConnectionsColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect && column.name === 'group') {
				column.valuesTeBeSelected = additionG.map((addition) => {
					return { value: addition.id ? addition.id : 0, text: addition.name };
				});
			}

			if (column.fieldType === FieldType.SingleSelect && column.name === 'product') {
				column.valuesTeBeSelected = articles.map((article) => {
					return { value: article.ID ? article.ID : 0, text: article.Name + '-' + article.BrandingName };
				});
			}
		});
		return AdditionGroupConnectionsColumns;
	}

	return (
		<>
			<Datatable<AdditionGroupConnections>
				columns={getColumns()}
				delete={deleteAdditionGroupConnection}
				emptyValue={emptyAdditionGroupConnections}
				getAll={getAllAdditionGroupsConnection}
				post={postAdditionGroupConnection}
				update={updateAdditionGroupConnection}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataAdditionGroupsConnections;
