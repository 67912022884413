import React, { FC } from 'react';

import { Coupon, CouponColumns, emptyCoupon } from '../../../models/coupons/coupons.model';
import {
	deleteCoupon,
	getAllCoupons,
	postCoupon,
	updateCoupon,
} from '../../../models/coupons/coupons.repository';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataCoupon: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Coupon>
				columns={CouponColumns}
				delete={deleteCoupon}
				emptyValue={emptyCoupon}
				getAll={getAllCoupons}
				post={postCoupon}
				update={updateCoupon}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataCoupon;
