import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components/DataTable';
import { Order, OrderColumns } from '../../models/order/order.model';
import { getAllOrders } from '../../models/order/order.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const OrderTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Order>
				columns={OrderColumns}
				getAll={getAllOrders}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default OrderTable;
