import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface LoyaltyRewards {
	Count: number;
	Reward: number;
	tenant?: number;
	ID?: number;
	articleName?: string;
}

export const LoyaltyRewardsColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Count', name: 'Count', fieldType: FieldType.Number },
	{
		dataName: 'Reward',
		name: 'Reward',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'articleName', name: 'articleName' },
];

export const emptyLoyaltyRewards: LoyaltyRewards = {
	Count: 0,
	Reward: 0,
};
