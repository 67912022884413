import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from './bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from './bootstrap/Modal';
import EditWindow from './editWindow';
import showNotification from './extras/showNotification';
import Icon from './icon/Icon';
import Table, { Columns } from './Table';
interface BaseDataTableProp<T> {
	isFluid: boolean;
	update?: (data: T) => Promise<T>;
	getAll: () => Promise<T[]>;
	delete?: (id: number) => Promise<any>;
	post?: (data: T) => Promise<T>;
	emptyValue?: T;
	columns: Columns[];
	name: string;
	costumRightHeader?: any;
}

export function Datatable<T>(props: BaseDataTableProp<T>) {
	const [itemToEdit, setItemToEdit] = useState<T | undefined>(undefined);
	const [itemToAdd, setItemToAdd] = useState<T | undefined>(undefined);
	const [itemToDelete, setItemToDelete] = useState<T | undefined>();
	const [data, setData] = useState<T[]>([]);
	const { t } = useTranslation(['translation', 'menu']);

	useEffect(() => {
		props.getAll().then((res) => {
			setData(res);
		});
	}, [props]);

	function editItem() {
		if (itemToEdit && props.update) {
			props.update(itemToEdit).then((res) => {
				props.getAll().then((_res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon={'check'} size='lg' className='me-1' />
							<span>{t(`successfullyEdited`)}</span>
						</span>,
						'',
					);
					setData(_res);
					setItemToEdit(undefined);
				});
			});
		}
	}

	function addItem() {
		if (itemToAdd && props.post) {
			props.post(itemToAdd).then((res) => {
				props.getAll().then((_res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon={'check'} size='lg' className='me-1' />
							<span>{t(`successfullyAdded`)}</span>
						</span>,
						'',
					);
					setData(_res);
					setItemToAdd(undefined);
				});
			});
		}
	}

	function deleteItem(item: T) {
		console.log('ITTEEEMM', item);
		// @ts-ignore
		if ((item.ID || item.id) && props.delete) {
			// @ts-ignore
			props.delete(item.ID ?? item.id).then((res) => {
				props.getAll().then((_res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon={'check'} size='lg' className='me-1' />
							<span>{t(`successfullyDeleted`)}</span>
						</span>,
						'',
					);
					setData(_res);
					setItemToAdd(undefined);
				});
			});
		}
	}

	return (
		<>
			<Card style={{ width: '100%' }} stretch={props.isFluid}>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='info'>
						<CardTitle>{t(props.name ? props.name : '')}</CardTitle>
					</CardLabel>
					<CardActions>
						{props.post !== undefined && (
							<Button
								color='info'
								icon='plus'
								isLight
								onClick={() => setItemToAdd(props.emptyValue)}>
								{t('createNew')}
							</Button>
						)}
						{props.costumRightHeader !== undefined && props.costumRightHeader}
					</CardActions>
				</CardHeader>
				<CardBody className='table-responsive' isScrollable={props.isFluid}>
					<Table
						hideEdit={props.update === undefined}
						hideDelete={props.delete === undefined}
						columns={props.columns}
						edit={(id) => setItemToEdit(id)}
						delete={(item: T) => setItemToDelete(item)}
						data={data}
					/>
				</CardBody>
				{/* <PaginationButtons
                    data={items}
                    label='items'
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                /> */}
			</Card>
			{itemToEdit !== undefined && (
				<EditWindow
					name={`${t(props.name ? props.name : '')} ${t('edit')}`}
					cancel={() => setItemToEdit(undefined)}
					save={() => editItem()}
					isOpen={itemToEdit !== undefined}
					setFormGroup={setItemToEdit}
					formGroup={itemToEdit}
					fields={props.columns}
				/>
			)}
			{itemToAdd !== undefined && (
				<EditWindow
					name={`${t(props.name ? props.name : '')} ${t('add')}`}
					cancel={() => setItemToAdd(undefined)}
					save={() => addItem()}
					isOpen={itemToAdd !== undefined}
					setFormGroup={setItemToAdd}
					formGroup={itemToAdd}
					fields={props.columns}
				/>
			)}
			<Modal
				titleId={`${t(props.name ? props.name : '')}` + ' ' + t('delete')}
				isOpen={itemToDelete !== undefined} // Example: state
				setIsOpen={() => setItemToDelete(undefined)} // Example: setState
				isStaticBackdrop={true}
				isCentered={true}
				size={'sm'} // 'sm' || 'lg' || 'xl'
				fullScreen={'sm'} // true || 'sm' || 'md' || 'lg' || 'xl' || 'xxl'
				isAnimation={true}>
				<ModalHeader
					setIsOpen={Function} // Example: setState
				>
					<ModalTitle id={''}>
						{`${t(props.name ? props.name : '')}` + ' ' + t('delete')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>Dieser Vorgang kann nicht mehr rückgängig gemacht werden</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={() => setItemToDelete(undefined)}>
						{t('cancel')}
					</Button>
					<Button
						color='info'
						icon='trash'
						onClick={() => {
							itemToDelete ? deleteItem(itemToDelete) : console.log();
							setItemToDelete(undefined);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
