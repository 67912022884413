import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface Settings {
	id?: number;
	key: string;
	value: string;
	tenant_id: number;
}

export const SettingsColumns: Columns[] = [
	{ dataName: 'id', name: 'id' },
	{ dataName: 'key', name: 'name', fieldType: FieldType.TextField },
	{ dataName: 'value', name: 'value', fieldType: FieldType.TextField },
];

export const emptySettings: Settings = {
	tenant_id: 0,
	key: '',
	value: '',
};
