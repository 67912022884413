import React, {FC, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import {
	AdditionProducts,
	AdditionProductsColumns,
	emptyAdditionProducts,
} from '../../../models/additionProducts/additionProducts.model';
import {
	deleteAdditionProducts,
	getAllAdditionProducts,
	postAdditionProducts,
	updateAdditionProducts,
} from '../../../models/additionProducts/additionProducts.repository';
import {FieldType} from "../../../components/editWindow";
import {getAllAdditionGroups} from "../../../models/additionGroups/additionGroups.repository";
import AdditionGroups from "../additionGroups/additionGroups";
import {AdditionGroup} from "../../../models/additionGroups/additionGroups.model";
interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataAdditionProducts: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [additionG, setAdditionG] = useState<AdditionGroup[]>([]);
	useEffect(() => {
		getAllAdditionGroups().then((res: AdditionGroup[]) => {
			setAdditionG(res);
		});
	}, []);

	function getColumns() {
		AdditionProductsColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = additionG.map((addition) => {
					return { value: addition.id ? addition.id : 0, text: addition.name };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return AdditionProductsColumns;
	}

	return (
		<>
			<Datatable<AdditionProducts>
				columns={getColumns()}
				delete={deleteAdditionProducts}
				emptyValue={emptyAdditionProducts}
				getAll={getAllAdditionProducts}
				post={postAdditionProducts}
				update={updateAdditionProducts}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataAdditionProducts;
