import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';

export interface Events {
	ImageSource: string;
	Title: string;
	tenant?: number;
	ID?: number;
}

export const EventColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'Title', name: 'title', fieldType: FieldType.TextField },
	{ dataName: 'ImageSource', name: 'img', fieldType: FieldType.Image },
];

export const emptyEvent: Events = {
	Title: '',
	ImageSource: '',
};
