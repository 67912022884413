import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components/DataTable';
import { emptyUser, User, UserColumns } from '../../models/nutzer/nutzer.model';
import {
	deleteUser,
	getAllUser,
	postUser,
	updateUser,
} from '../../models/nutzer/nutzer.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const UserTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<User>
				columns={UserColumns}
				getAll={getAllUser}
				delete={deleteUser}
				emptyValue={emptyUser}
				post={postUser}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default UserTable;
