import { PER_COUNT } from '../components/PaginationButtons';
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getPictureUrl } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import Button from './bootstrap/Button';
import { FieldType } from './editWindow';

export interface Columns {
	displayed?: boolean;
	showImage?: boolean;
	name: string;
	dataName: string;
	fieldType?: FieldType;
	valuesTeBeSelected?: { value: string | number; text: string }[];
}

const Table = (props: {
	hideEdit: boolean;
	hideDelete: boolean;
	data: any[];
	edit: (id: any) => void;
	columns: Columns[];
	delete: (id: any) => void;
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['translation', 'menu']);
	const [upcomingEventsInfoOffcanvas, setUpcomingEventsInfoOffcanvas] = useState(false);
	const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
	const handleUpcomingDetails = () => {
		setUpcomingEventsInfoOffcanvas(!upcomingEventsInfoOffcanvas);
	};
	const handleUpcomingEdit = () => {
		setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas);
	};

	function getValueForColumn(column: Columns, data: any) {
		switch (column.fieldType) {
			case FieldType.Switch:
				return (
					<td key={column.dataName + 'column'}>
						<div className='d-flex align-items-center'>
							<span
								className={classNames(
									'border border-2',
									[`border-${themeStatus}`],
									'rounded-circle',
									`bg-${!!data[column.dataName] ? 'success' : 'danger'}`,
									'p-2 me-2',
								)}
							/>
						</div>
					</td>
				);

			case FieldType.Image:
				return (
					<td key={column.dataName + 'column'}>
						<img
							style={{ height: 60 }}
							src={getPictureUrl(data[column.dataName])}></img>
					</td>
				);

			default:
				if (column.showImage === true) {
					return (
						<td key={column.dataName + 'column'}>
							<img
								style={{ height: 60 }}
								src={getPictureUrl(data[column.dataName])}></img>
						</td>
					);
				} else {
					return <td key={column.dataName + 'column'}> {data[column.dataName]}</td>;
				}
		}
	}

	return (
		<table className='table table-modern'>
			<thead>
				<tr>
					{props.columns.map((column) => {
						if (column.displayed !== false) {
							return <th key={column.dataName}>{t(column.name)}</th>;
						}
					})}
					{(props.hideDelete === false || props.hideEdit === false) && (
						<th key={'AKTION'}>{t('action')}</th>
					)}
				</tr>
			</thead>
			<tbody>
				{props?.data?.map((data) => {
					return (
						<tr key={data.ID}>
							{props.columns.map((column) => {
								if (column.displayed !== false) {
									return getValueForColumn(column, data);
								}
							})}
							<td>
								{props.hideEdit === false && (
									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', {
											'border-light': !darkModeStatus,
										})}
										icon='Edit'
										onClick={() => props.edit(data)}>
										{t('edit')}
									</Button>
								)}
								{props.hideDelete === false && (
									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', {
											'border-light': !darkModeStatus,
										})}
										icon='trash'
										onClick={() => props.delete(data)}>
										{t('delete')}
									</Button>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Table;
