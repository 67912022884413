import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Coupon, CouponType } from './coupons.model';

function getUrl(): string {
	return `/coupon`;
}

export function getAllCoupons(): Promise<Coupon[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Coupon[]>) => resolve(res.data));
	});
}

export function updateCoupon(coupon: Coupon): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, coupon).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function postCoupon(coupon: Coupon): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), coupon).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function deleteCoupon(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildCoupon(
	Name: string,
	Type: CouponType,
	Amount: number,
	Active: boolean,
	ID?: number,
): Coupon {
	return {
		Name,
		Type,
		Amount,
		Active,
		tenant: getTenantID(),
		ID,
	};
}
