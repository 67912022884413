import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Events } from './event.model';

function getUrl(): string {
	return `/event`;
}

export function postEvent(event: Events): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), event).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function getAllEvent(): Promise<Events[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Events[]>) => resolve(res.data));
	});
}

export function deleteEvent(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function updateEvent(tenant: Events): Promise<Events> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, tenant).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function buildEvent(ImageSource: string, Title: string, ID?: number): Events {
	return {
		ImageSource,
		Title,
		ID,
		tenant: getTenantID(),
	};
}
