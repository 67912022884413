import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Type, TypeStats } from './type.model';

function getUrl(): string {
	return `/types`;
}

export function postType(tenant: Type): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), tenant).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function getAllTypes(): Promise<Type[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Type[]>) => resolve(res.data));
	});
}

export function getTypeWithID(id: number): Promise<Type> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/${id}`).then((res: AxiosResponse<Type[]>) => resolve(res.data[0]));
	});
}
export function getTypeStats(): Promise<TypeStats[]> {
	return new Promise((resolve, reject) => {
		axios.get(`typeStats/id`).then((res: AxiosResponse<TypeStats[]>) => {
			let typestats = res.data.filter(ts => ts.Type !== null)
			resolve(typestats)
		});
	});
}

export function getTotalOrders(): Promise<{total: number}> {

	return new Promise((resolve, reject) => {
		axios.get('totalOrder/id').then((res: AxiosResponse<{ total: number }>) => {
			resolve(res.data)
		});
	})

}

export function deleteType(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function updateType(tenant: Type): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, tenant).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function buildType(
	Name: string,
	speakBubbleText: string,
	combinable: boolean,
	ID?: number,
): Type {
	return {
		Name,
		tenant: getTenantID(),
		combinable,
		speakBubbleText,
		ID,
	};
}
