import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { SubCategory } from './warengruppe.model';

function getUrl(): string {
	return `/brandings`;
}

export function getAllSubCategory(): Promise<SubCategory[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<SubCategory[]>) => resolve(res.data));
	});
}

export function updateSubCategory(warengruppe: SubCategory): Promise<SubCategory> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, warengruppe).then((res: any) => {
			resolve(res);
		});
	});
}

export function postSubCategory(warengruppe: SubCategory): Promise<SubCategory> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), warengruppe).then((res: any) => {
			resolve(res);
		});
	});
}

export function deleteSubCategory(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildSubCategory(Name: string, Kategorie: number, ID?: number): SubCategory {
	return {
		Name,
		Kategorie,
		ID,
		tenant: getTenantID(),
	};
}
