import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AdditionGroup } from './additionGroups.model';

function getUrl(): string {
	return `/additionGroups`;
}

export function getAllAdditionGroups(): Promise<AdditionGroup[]> {
	return new Promise((resolve, reject) => {
		axios
			.get(`${getUrl()}/id`)
			.then((res: AxiosResponse<AdditionGroup[]>) => resolve(res.data));
	});
}

export function updateAdditionGroup(additionGroup: AdditionGroup): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, additionGroup).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function postAdditionGroup(additionGroup: AdditionGroup): Promise<any> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), additionGroup).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function deleteAdditionGroup(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			id: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildAdditionGroup(name: string, id?: number): AdditionGroup {
	return {
		name,
		tenant: getTenantID(),
		id,
	};
}
