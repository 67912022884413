import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { addPicture, getPictureUrl } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import Button from './bootstrap/Button';
import Checks from './bootstrap/forms/Checks';
import FormGroup from './bootstrap/forms/FormGroup';
import Input from './bootstrap/forms/Input';
import Select from './bootstrap/forms/Select';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import { Columns } from './Table';
import Textarea from "./bootstrap/forms/Textarea";
import { TextField as MuiTextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export enum FieldType {
	TextField = 'TextField',
	LongTextfield = 'LongTextfield',
	Switch = 'Switch',
	FileUpload = 'Fileupload',
	DropDown = 'Dropdown',
	Multiselect = 'Multiselect',
	SingleSelect = 'SingleSelect',
	Number = 'Number',
	Image = 'Image',
	Password = 'Password',
}

const EditWindow = (props: {
	name: string;
	isOpen: boolean;
	cancel: () => void;
	save: () => void;
	fields: Columns[];
	formGroup: any;
	setFormGroup: (value: any) => void;
}) => {
	const { t } = useTranslation(['translation', 'menu']);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [password, setPassword] = useState('');

	function getInputForFieldType(field: Columns) {
		switch (field.fieldType) {
			case FieldType.LongTextfield:
				return (
					<Textarea
						// @ts-ignore
						onChange={(text: any) =>
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: text.nativeEvent.target.value,
							})
						}
						// @ts-ignore
						value={props.formGroup[field.dataName]}
					/>
				)
			case FieldType.TextField:
				return (
					<Input
						// @ts-ignore
						onChange={(text: any) =>
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: text.nativeEvent.target.value,
							})
						}
						// @ts-ignore
						value={props.formGroup[field.dataName]}
					/>
				);

			case FieldType.Number:
				return (
					<Input
						type='number'
						// @ts-ignore
						onChange={(text: any) =>
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: Number(text.nativeEvent.target.value),
							})
						}
						// @ts-ignore
						value={props.formGroup[field.dataName]}
					/>
				);

			case FieldType.SingleSelect:
				return (
					<Select
						ariaLabel={field.name}
						placeholder={field.name}
						list={field.valuesTeBeSelected?.slice().sort((a, b) => a.text.localeCompare(b.text))}
						//@ts-ignore
						onChange={(value: any) =>
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: value.nativeEvent.target.value,
							})
						}
						value={props.formGroup[field.dataName]}
					/>
					// <Autocomplete
					// 	disablePortal
					// 	id="combo-box-demo"
					// 	options={field.valuesTeBeSelected?.map((a: any) => { return { ...a, label: a.text } }) ?? []}
					// 	sx={{ width: 300 }}
					// 	value={props.formGroup[field.dataName]}
					// 	onChange={(value: any) => {
					// 		props.setFormGroup({
					// 			...props.formGroup,
					// 			[field.dataName]: value.nativeEvent.target.value,
					// 		})
					// 	}
					// 	}
					// 	renderInput={(params) => <MuiTextField

					// 		value={props.formGroup[field.dataName]}
					// 		{...params} label={field.name} />}
					// />
				);
			case FieldType.Multiselect:
				return (
					<Select
						ariaLabel={field.name}
						placeholder={field.name}
						list={field.valuesTeBeSelected}
						multiple={true}
						//@ts-ignore
						onChange={(value: any) => {
							console.log("#-#-#-", value.nativeEvent.target.value)
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: handleMultiSelectChange(value.nativeEvent.target.value, props.formGroup[field.dataName])
							})
						}
						}
						value={handleMultiSelectChangeValue(props.formGroup[field.dataName])}
					/>
				);

			case FieldType.Switch:
				return (
					<Checks
						type='switch'
						label={<>{field.name}</>}
						//@ts-ignore
						onChange={(value) =>
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: !props.formGroup[field.dataName],
							})
						}
						checked={props.formGroup[field.dataName]}
					/>
				);
			case FieldType.Password:
				return (
					<>
						<Input
							// @ts-ignore
							onChange={(text: any) => {
								props.setFormGroup({
									...props.formGroup,
									[field.dataName]: text.nativeEvent.target.value,
								});
								setPassword(text.nativeEvent.target.value);
							}}
							// @ts-ignore
							value={password}
						/>
					</>
				);
			case FieldType.Image:
				return getImageView(field);

			default:
				return (
					<div>
						Wrong configured: {field.fieldType}: {field.dataName}
					</div>
				);
		}
	}

	function handleMultiSelectChange(clickedValue: any, currentValues: any) {
		const valueToParse = (currentValues === null || currentValues === undefined || currentValues === "") ? "[]" : currentValues
		let currentValueParsed = JSON.parse(valueToParse)
		let isValueAlreadyClicked = currentValueParsed.includes(clickedValue)
		let newArray;
		newArray = isValueAlreadyClicked ? currentValueParsed.filter((val: any) => val === clickedValue) : currentValueParsed.concat(clickedValue)
		console.log(newArray)
		return JSON.stringify(newArray)
	}

	function handleMultiSelectChangeValue(currentValues: string) {
		try {
			const valueToParse = (currentValues === null || currentValues === undefined || currentValues === "") ? "[]" : currentValues
			return JSON.parse(valueToParse)
		} catch (err) {
			console.error("MULTISELECT ERROR JSON NOT PARSABLE", err)
		}
	}

	function getImageView(field: Columns) {
		if (
			props.formGroup[field.dataName] !== '' &&
			props.formGroup[field.dataName] !== undefined
		) {
			return (
				<div>
					<img
						style={{ height: 120 }}
						src={getPictureUrl(props.formGroup[field.dataName])}></img>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						className={classNames('text-nowrap', {
							'border-light': !darkModeStatus,
						})}
						icon='Trash'
						onClick={() =>
							props.setFormGroup({ ...props.formGroup, [field.dataName]: '' })
						}>
						{t('removeImage')}
					</Button>
				</div>
			);
		} else {
			return (
				<Input
					type='file'
					// @ts-ignore
					onChange={(text: any) => {
						// @ts-ignore
						addPicture(text.target.files[0]).then((res) => {
							// @ts-ignore
							props.setFormGroup({
								...props.formGroup,
								[field.dataName]: text.target.files[0].name,
							});
						});
					}}
				/>
			);
		}
	}

	return (
		<OffCanvas
			setOpen={props.cancel}
			isOpen={props.isOpen}
			titleId='upcomingEdit'
			isBodyScroll
			placement='end'>
			<OffCanvasHeader setOpen={props.cancel}>
				<OffCanvasTitle id='upcomingEdit'>{props.name}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				<div className='row g-4'>
					{props.fields.map((field) => {
						return (
							<div key={field.dataName} className='col-12'>
								{field.fieldType !== undefined && (
									<FormGroup id={field.dataName} label={field.name}>
										{getInputForFieldType(field)}
									</FormGroup>
								)}
							</div>
						);
					})}
				</div>
			</OffCanvasBody>
			<div className='row m-0'>
				<div className='col-12 p-3'>
					<Button color='info' className='w-100' onClick={() => props.save()}>
						{t('save')}
					</Button>
				</div>
			</div>
		</OffCanvas>
	);
};

export default EditWindow;
