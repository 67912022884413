import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { FieldType } from '../../../components/editWindow';
import { Article, ArticleColumns, emptyArticle } from '../../../models/artikel/artikel.model';
import {
	deleteArticle,
	getAllArticle,
	postArticle,
	updateArticle,
} from '../../../models/artikel/artikel.repository';
import { getAllSubCategory } from '../../../models/warengruppe/warengruppe-repository';
import { SubCategory } from '../../../models/warengruppe/warengruppe.model';
import { getAllAdditionGroups } from '../../../models/additionGroups/additionGroups.repository';
import { AdditionGroup } from '../../../models/additionGroups/additionGroups.model';
interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataArticle: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [category, setCategory] = useState<SubCategory[]>([]);
	//const [additionalGroups, setAdditionalGroups] = useState<AdditionGroup[]>([]);

	useEffect(() => {
		getAllSubCategory().then((res: SubCategory[]) => {
			setCategory(res);
		});
		// getAllAdditionGroups().then((res: AdditionGroup[]) => {
		// 	setAdditionalGroups(res)
		// });
	}, []);

	function getColumns() {
		ArticleColumns.forEach((column) => {
			if (column.dataName === "Branding") {
				let valueForSelect = category.map((cat) => {
					return { value: cat.ID ? cat.ID : 0, text: cat.Name + ' - ' + cat.KategorieName };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
			// if (column.dataName === "additionGroup") {
			// 	let valueForSelect = additionalGroups.map((additionalGroup) => {
			// 		return { value: additionalGroup.id ? additionalGroup.id : 0, text: additionalGroup.name };
			// 	});
			// 	column.valuesTeBeSelected = valueForSelect;
			// }
		});
		return ArticleColumns;
	}

	return (
		<>
			<Datatable<Article>
				columns={getColumns()}
				delete={deleteArticle}
				emptyValue={emptyArticle}
				getAll={getAllArticle}
				post={postArticle}
				update={updateArticle}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataArticle;
