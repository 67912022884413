import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import {AdditionProducts} from "./additionProducts.model";

function getUrl(): string {
    return `/additionProducts`;
}

export function getAllAdditionProducts(): Promise<AdditionProducts[]> {
    return new Promise((resolve, reject) => {
        axios
            .get(`${getUrl()}/id`)
            .then((res: AxiosResponse<AdditionProducts[]>) => resolve(res.data));
    });
}

export function updateAdditionProducts(additionProduct: AdditionProducts): Promise<any> {
    return new Promise((resolve, reject) => {
        axios.put(`${getUrl()}`, additionProduct).then((res: any) => {
            resolve(res.data);
        });
    });
}

export function postAdditionProducts(additionProduct: AdditionProducts): Promise<any> {
    return new Promise((resolve, reject) => {
        axios.post(getUrl(), additionProduct).then((res: any) => {
            resolve(res.data);
        });
    });
}

export function deleteAdditionProducts(id: number): Promise<any> {
    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            id: id,
        },
    };
    return axios.delete(getUrl(), options);
}

