import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../../components/DataTable';
import { FieldType } from '../../../components/editWindow';
import { Article } from '../../../models/artikel/artikel.model';
import { getAllArticle } from '../../../models/artikel/artikel.repository';
import {
	emptyLoyaltyRewards,
	LoyaltyRewards,
	LoyaltyRewardsColumns,
} from '../../../models/loyaltyRewards/loyaltyPoints.model';
import {
	deleteLoyaltyRewards,
	getAllLoyaltyRewards,
	postLoyaltyRewards,
	updateLoyaltyRewards,
} from '../../../models/loyaltyRewards/loyaltyPoints.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const BaseDataLoyaltyRewards: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	const [article, setArticle] = useState<Article[]>([]);
	useEffect(() => {
		getAllArticle().then((res: Article[]) => {
			setArticle(res);
		});
	}, []);

	function getColumns() {
		LoyaltyRewardsColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = article.map((art) => {
					return { value: art.ID ? art.ID : 0, text: art.Name };
				});
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return LoyaltyRewardsColumns;
	}

	return (
		<>
			<Datatable<LoyaltyRewards>
				columns={getColumns()}
				delete={deleteLoyaltyRewards}
				emptyValue={emptyLoyaltyRewards}
				getAll={getAllLoyaltyRewards}
				post={postLoyaltyRewards}
				update={updateLoyaltyRewards}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default BaseDataLoyaltyRewards;
