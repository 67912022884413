import { FieldType } from '../../components/editWindow';
import { Columns } from '../../components/Table';
import { AdditionGroup } from '../additionGroups/additionGroups.model';

export interface Article {
	ID?: number;
	PLU: string;
	Name: string;
	Beschreibung: string;
	ImageSource: string;
	Kategorie?: string;
	Type?: number;
	Branding: number;
	Preis: number;
	Timestamp?: Date;
	tenant?: number;
	BrandingName?: string;
	CategorieName?: string;
	CategorieID?: number;
	TypeID?: number;
	TypeName?: string;
	additionalInformation?: string;
	//additionGroup: string
}

export const ArticleColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'PLU', name: 'PLU', fieldType: FieldType.TextField },
	{ dataName: 'Name', name: 'Name', fieldType: FieldType.TextField },
	{ dataName: 'Beschreibung', name: 'Beschreibung', fieldType: FieldType.TextField },
	{ dataName: 'BrandingName', name: 'branding' },
	{ dataName: 'Preis', name: 'Preis', fieldType: FieldType.Number },
	{ dataName: 'additionalInformation', name: 'additionalInformation', fieldType: FieldType.LongTextfield },
	// {
	// 	dataName: 'additionGroup',
	// 	name: 'Zusatz-Gruppe',
	// 	fieldType: FieldType.Multiselect,
	// 	valuesTeBeSelected: [],
	// 	displayed: false,
	// },
	{
		dataName: 'Branding',
		name: 'Branding',
		fieldType: FieldType.SingleSelect,
		valuesTeBeSelected: [],
		displayed: false,
	},
	{ dataName: 'ImageSource', name: 'img', fieldType: FieldType.Image },
	{ dataName: 'AdditionGroupName', name: 'Zusatz-Gruppe' },
];

export const emptyArticle: Article = {
	PLU: '',
	Name: '',
	Beschreibung: '',
	Branding: 0,
	Preis: 0,
	ImageSource: '',
	additionalInformation: '',
	//additionGroup: '[]'
};
