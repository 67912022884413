import axios from 'axios';
import { environment } from '../../common/environment';
import { ArrowBackIos } from '../../components/icon/material-icons';

const getUrl = (): string => {
	return `${environment.backendUrlPublic}/loginAdmin`;
};

interface LoginResponse {
	sessionToken: string;
	succesfull: boolean;
	tenant: 0;
	userID: string;
}

export function loginValid(loginData: { Username: string; Password: string }): Promise<boolean> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), loginData).then((res) => {
			const status = res.data.succesfull;
			if (status === true) {
				setBaseURL(res.data);
				resolve(true);
			} else {
				resolve(false);
			}
		});
	});
}

export function setBaseURL(loginResponse: LoginResponse): void {
	const baseURL = `${environment.backendURL}/${loginResponse.sessionToken}/admin/${loginResponse.userID}/${loginResponse.tenant}`;
	localStorage.setItem('baseURL', baseURL);
	axios.defaults.baseURL = baseURL;
}
