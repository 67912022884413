import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { GameRewards } from './winnings.model';

function getUrl(): string {
	return `/rewards`;
}

export function postGameRewards(printer: GameRewards): Promise<GameRewards> {
	return axios.post(getUrl(), printer);
}

export function getAllGameRewards(): Promise<GameRewards[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<GameRewards[]>) => resolve(res.data));
	});
}

export function deleteWinningsGameRewards(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function updateGameRewards(reward: GameRewards): Promise<GameRewards> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, reward).then((res: any) => {
			resolve(res);
		});
	});
}

export function buildGameRewards(
	Title: string,
	Anzahl: number,
	ArticleID: number,
	ID?: number,
): GameRewards {
	return {
		Title,
		Anzahl,
		ArticleID,
		tenant: getTenantID(),
		ID,
	};
}
