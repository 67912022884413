import React from 'react';
import { adminData, baseDataPages, dashboardMenu, demoPages, layoutMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

function getBaseDataRoutes() {
	let baseDataRoutes = [];
	for (let x in baseDataPages.baseData.subMenu) {
		// @ts-ignore
		baseDataRoutes.push({
			// @ts-ignore
			path: baseDataPages.baseData.subMenu[x].path,
			element: <DashboardHeader />,
			exact: true,
		});
	}
	return baseDataRoutes;
}
function getAdminDataRoutes() {
	let adminDataRoutes = [];
	for (let x in adminData) {
		// @ts-ignore
		if (adminData[x].path) {
			// @ts-ignore
			adminDataRoutes.push({
				// @ts-ignore
				path: adminData[x].path,
				element: <DashboardHeader />,
				exact: true,
			});
		}
	}
	return adminDataRoutes;
}

const headers = [
	{ path: layoutMenu.pageLayout.subMenu.onlySubheader.path, element: null, exact: true },
	{ path: layoutMenu.pageLayout.subMenu.onlyContent.path, element: null, exact: true },
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
]
	.concat(getBaseDataRoutes())
	.concat(getAdminDataRoutes());

export default headers;
