import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import {Settings} from "./settings.model";

function getUrl(): string {
	return `/settings`;
}

export function getAllSettings(): Promise<Settings[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/id`).then((res: AxiosResponse<Settings[]>) => resolve(res.data));
	});
}

export function updateSettings(obergruppe: Settings): Promise<Settings> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}`, obergruppe).then((res: any) => {
			resolve(res.data);
		});
	});
}

export function postSettings(obergruppe: Settings): Promise<Settings> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), obergruppe).then((res) => {
			resolve(res.data);
		});
	});
}

export function deleteSettings(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			ID: id,
		},
	};
	return axios.delete(getUrl(), options);
}

export function buildSettings(
	key: string,
	value: string,
	id?: number
): Settings {
	return {
		key: key,
		value: value,
		tenant_id: getTenantID() as number,
		id: id,
	};
}
